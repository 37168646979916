@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "base/button.css";
@import "base/embed.css";
@import "base/input.css";
@import "base/link.css";
@import "base/pharma.css";
@import "base/type.css";
@import "base/block-grid.css";

@import "../../node_modules/swiper/swiper.min.css";


@layer base {
    html {
        @apply font-sans text-base antialiased;
    }
}

@layer components {
    @import "components/loading.css";

    /* Richtext */
    .richtext {
        line-height: 1.35;
    }

    .richtext > p {
        @apply leading-normal;
    }

    .richtext > p:not(:last-child),
    .richtext > ul:not(:last-child),
    .richtext > ol:not(:last-child) {
        @apply mb-5;
    }

    .richtext a:not(.btn) {
        @apply text-orange underline;
    }
        

    /*.richtext h2:not(:last-child),
    .richtext h3:not(:last-child) {
        @apply mb-5;
    }*/

    .richtext h2 {
        @apply text-2xl leading-tight;
    }

    .richtext h3 {
        @apply text-2xl leading-tight;
    }

    h1.htag {
        @apply text-4xl;
    }

    @media screen and (min-width: 1024px) {

        .richtext h2 {
            @apply  text-4xl;
        }

        .richtext h3 {
            @apply  text-4xl;
        }

        h1.htag {
            @apply text-8xl;
        }
    }

    .richtext ul {
        list-style: disc;
    }

    .richtext ol {
        list-style: revert;
    }

    .richtext ul li,
    .richtext ol li {
        @apply ml-4 pl-0;
    }

    /* HTag Headlines */
    h1.htag,
    h2.htag,
    h3.htag,
    h4.htag,
    h5.htag,
    h6.htag {
        @apply font-headline leading-tight;
    }

    h2.htag {
        @apply leading-snug;
    }

    h5.htag {
        @apply text-base;
    }

    h6.htag {
        @apply text-sm;
    }
}

[x-cloak] {
    display: none !important;
}

.input-validation-error {
    @apply !border-red-500;
}

.hero-backdrop {
    position: absolute;
    top: -80px;
    left: 0;
    right: 0;
    z-index: 1;
    border-radius: 31% 72% 0 0;
    margin-left: -120px;
    margin-right: -120px;
    height: 450px;
}

@media screen and (min-width: 1024px) {
    .hero-backdrop {
        top: -120px;
    }
}