/* purgecss start ignore */
.btn {
    @apply bg-primary text-white font-bold md:text-lg md:px-8 px-4 py-2 rounded-lg disabled:opacity-50;
}

.btn-secondary {
    @apply bg-orange;
}

.btn-rose {
    @apply bg-rose;
}

.btn-white {
    @apply bg-white text-dark font-bold md:text-lg md:px-8 px-4 py-2 rounded-lg;
}

/* purgecss end ignore */
