/* purgecss start ignore */
.embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
    padding-top: 56%;

    embed, iframe, object, video {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }
}

.embed-responsive-16by9 {
    padding-top: 56.25%;
}
/* purgecss end ignore */