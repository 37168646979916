/* purgecss start ignore */
.blockitem h1,
.blockitem h2,
.blockitem h3,
.blockitem h4,
.blockitem h5 {
    @apply font-medium leading-snug m-0;
}


.blockitem h1 {
    @apply font-headline;
}

.blockitem h2 {
    @apply font-headline text-3xl;
}

.blockitem h3 {
    @apply font-headline;
}

.blockitem h4 {
    @apply font-headline;
}

h1, h2, h3, h4, h5, h6 {
    @apply font-headline;
}

/* purgecss end ignore */
